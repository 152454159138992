<template>
  <div id="echart-1">
    <div class="echart-1-top">
      <el-row>
        <el-col :span="8">
          <div class="row-left">
            <div class="top">待办事项</div>
            <div class="top-content">
              <el-row>
                <el-col :span="6">
                  <strong>5</strong>
                  <div>涨粉订单</div>
                </el-col>
                <el-col :span="6">
                  <strong>5</strong>
                  <div>充值订单</div>
                </el-col>
                <el-col :span="6">
                  <strong>5</strong>
                  <div>体现订单</div>
                </el-col>
                <el-col :span="6">
                  <strong>5</strong>
                  <div>待审核</div>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-col>
        <el-col :span="15" :offset="1">
          <div class="row-left">
            <div class="top">平台数据</div>
            <div class="top-content">
              <el-row>
                <el-col :span="4">
                  <strong class="top-content-color">200</strong>
                  <div>广告主数量</div>
                </el-col>
                <el-col :span="4">
                  <strong class="top-content-color">3000</strong>
                  <div>广告总决赛</div>
                </el-col>
                <el-col :span="4">
                  <strong class="top-content-color">200</strong>
                  <div>流量主数量</div>
                </el-col>
                <el-col :span="4">
                  <strong class="top-content-color">5677678</strong>
                  <div>累计供粉数</div>
                </el-col>
                <el-col :span="4">
                  <strong class="top-content-color">43536.55</strong>
                  <div>流量主帅</div>
                </el-col>
                <el-col :span="4">
                  <strong class="top-content-color">567575.00</strong>
                  <div>涨粉总收益</div>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="echart-1-buttom">
      <div class="echart-1-buttom-time">
        <el-date-picker v-model="startTime" type="date" placeholder="开始时间">
        </el-date-picker
        >&nbsp; <span>——</span>&nbsp;
        <el-date-picker v-model="endTime" type="date" placeholder="结束时间">
        </el-date-picker>
        <el-button type="primary" class="query">查询</el-button>
      </div>
      <div id="echartsContent"></div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      startTime: "",
      endTime: "",
      name
    };
  },
  mounted() {
    this.getEchartsData();
  },
  methods: {
    //   获取echarts数据
    getEchartsData() {
      const ModelEchartsContent = this.$echarts.init(
        document.getElementById("echartsContent")
      );
      const option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
              name:'日期',
            type: "category",
            boundaryGap: false,
            data: [
              "01/01",
              "01/02",
              "01/03",
              "01/04",
              "01/05",
              "01/06",
              "01/07",
              "01/08",
            ],
          },
        ],
        yAxis: [
          {
              name:'涨粉',
            type: "value",
          },
        ],
        series: [
          {
            name: "累计失效",
            type: "line",
            stack: "总量",
            smooth: true,
            lineStyle: {
              width: 0,
            },
            showSymbol: false,
            areaStyle: {
              color: "rgb(216,1,28)",
            },
            data: [220, 182, 191, 234, 290, 330, 310, 330],
          },

          {
            name: "累计涨粉",
            type: "line",
            stack: "总量",
            smooth: true,
            lineStyle: {
              width: 0,
            },
            showSymbol: false,
            label: {
              normal: {
                show: true,
                position: "top",
              },
            },
            areaStyle: {
              color: "rgb(2,167,240)",
            },
            data: [820, 932, 901, 934, 1290, 1330, 1320, 330],
          },
        ],
      };
      //end				

      ModelEchartsContent.setOption(option);
      window.addEventListener("resize", function () {
        ModelEchartsContent.resize();
      });
    },
  },
};
</script>
<style scoped lang='scss'>
#echart-1 {
  padding: 10px;
  width: 100%;
  .echart-1-top {
    .row-left {
       box-shadow:-1px 3px 13px 1px rgb(212, 212, 212);
      .top {
        background: rgb(242, 242, 242);
        height: 40px;
        line-height: 40px;
        text-align: center;
        font-weight: 700;
      }
      .top-content {
        padding: 30px 0;
        text-align: center;
        strong {
          display: inline-block;
          font-size: 18px;
          margin-bottom: 10px;
        }
        .top-content-color {
          color: rgb(2, 167, 240);
        }
      }
    }
    /deep/ .el-row {
      //   margin: 0 !important;
    }
  }
  .echart-1-buttom {
    height: 600px;
    // border: 1px solid rgb(212, 212, 212);
       box-shadow:-1px 3px 13px 1px rgb(212, 212, 212);

    margin-top: 40px;
    .echart-1-buttom-time {
      padding: 20px 40px;
      span {
        color: rgb(212, 212, 212);
      }
      .query {
        margin-left: 10px;
        width: 120px;
      }
    }
    #echartsContent {
      width: 100%;
      height: 400px;
      margin-top: 20px;
    }
  }
}
</style>